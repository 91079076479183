const countries = {
    "AX": "Aaland Islands",
    "AF": "Afghanistan",
    "AL": "Albania",
    "DZ": "Algeria",
    "AS": "American Samoa",
    "AD": "Andorra",
    "AO": "Angola",
    "AI": "Anguilla",
    "AQ": "Antarctica",
    "AG": "Antigua And Barbuda",
    "AR": "Argentina",
    "AM": "Armenia",
    "AW": "Aruba",
    "AU": "Australia",
    "AT": "Austria",
    "AZ": "Azerbaijan",
    "BS": "Bahamas",
    "BH": "Bahrain",
    "BD": "Bangladesh",
    "BB": "Barbados",
    "BY": "Belarus",
    "BE": "Belgium",
    "BZ": "Belize",
    "BJ": "Benin",
    "BM": "Bermuda",
    "BT": "Bhutan",
    "BO": "Bolivia",
    "BA": "Bosnia And Herzegowina",
    "BW": "Botswana",
    "BV": "Bouvet Island",
    "BR": "Brazil",
    "IO": "British Indian Ocean Territory",
    "BN": "Brunei Darussalam",
    "BG": "Bulgaria",
    "BF": "Burkina Faso",
    "BI": "Burundi",
    "KH": "Cambodia",
    "CM": "Cameroon",
    "CA": "Canada",
    "CV": "Cape Verde",
    "KY": "Cayman Islands",
    "CF": "Central African Republic",
    "TD": "Chad",
    "CL": "Chile",
    "CN": "China",
    "CX": "Christmas Island",
    "CC": "Cocos (Keeling) Islands",
    "CO": "Colombia",
    "KM": "Comoros",
    "CD": "Congo, Democratic Republic Of (Was Zaire)",
    "CG": "Congo, Republic Of",
    "CK": "Cook Islands",
    "CR": "Costa Rica",
    "CI": "Cote D'Ivoire",
    "HR": "Croatia",
    "CU": "Cuba",
    "CY": "Cyprus",
    "CZ": "Czech Republic",
    "DK": "Denmark",
    "DJ": "Djibouti",
    "DM": "Dominica",
    "DO": "Dominican Republic",
    "EC": "Ecuador",
    "EG": "Egypt",
    "SV": "El Salvador",
    "GQ": "Equatorial Guinea",
    "ER": "Eritrea",
    "EE": "Estonia",
    "ET": "Ethiopia",
    "FK": "Falkland Islands (Malvinas)",
    "FO": "Faroe Islands",
    "FJ": "Fiji",
    "FI": "Finland",
    "FR": "France",
    "FX": "France Metropolitan",
    "GF": "French Guiana",
    "PF": "French Polynesia",
    "TF": "French Southern Territories",
    "GA": "Gabon",
    "GM": "Gambia",
    "GE": "Georgia",
    "DE": "Germany",
    "GH": "Ghana",
    "GI": "Gibraltar",
    "GR": "Greece",
    "GL": "Greenland",
    "GD": "Grenada",
    "GP": "Guadeloupe/St Barth/St Martin",
    "GU": "Guam",
    "GT": "Guatemala",
    "GG": "Guernsey",
    "GN": "Guinea",
    "GW": "Guinea-Bissau",
    "GY": "Guyana",
    "HT": "Haiti",
    "HM": "Heard And Mc Donald Islands",
    "HN": "Honduras",
    "HK": "Hong Kong",
    "HU": "Hungary",
    "IS": "Iceland",
    "IN": "India",
    "ID": "Indonesia",
    "IR": "Iran (Islamic Republic Of)",
    "IQ": "Iraq",
    "IE": "Ireland",
    "IM": "Isle Of Man",
    "IL": "Israel",
    "IT": "Italy",
    "JM": "Jamaica",
    "JP": "Japan",
    "JO": "Jordan",
    "KZ": "Kazakhstan",
    "KE": "Kenya",
    "KI": "Kiribati",
    "KP": "Korea, Democratic People'S Republic Of",
    "KR": "Korea, Republic Of",
    "KW": "Kuwait",
    "KG": "Kyrgyzstan",
    "LA": "Lao People'S Democratic Republic",
    "LV": "Latvia",
    "LB": "Lebanon",
    "LS": "Lesotho",
    "LR": "Liberia",
    "LY": "Libyan Arab Jamahiriya",
    "LI": "Liechtenstein",
    "LT": "Lithuania",
    "LU": "Luxembourg",
    "MO": "Macau",
    "MK": "Macedonia",
    "MG": "Madagascar",
    "MW": "Malawi",
    "MY": "Malaysia",
    "MV": "Maldives",
    "ML": "Mali",
    "MT": "Malta",
    "MH": "Marshall Islands",
    "MQ": "Martinique",
    "MR": "Mauritania",
    "MU": "Mauritius",
    "YT": "Mayotte",
    "MX": "Mexico",
    "FM": "Micronesia, Federated States Of",
    "MD": "Moldova, Republic Of",
    "MC": "Monaco",
    "MN": "Mongolia",
    "ME": "Montenegro",
    "MS": "Montserrat",
    "MA": "Morocco",
    "MZ": "Mozambique",
    "MM": "Myanmar",
    "NA": "Namibia",
    "NR": "Nauru",
    "NP": "Nepal",
    "NL": "Netherlands",
    "AN": "Netherlands Antilles",
    "NC": "New Caledonia",
    "NZ": "New Zealand",
    "NI": "Nicaragua",
    "NE": "Niger",
    "NG": "Nigeria",
    "NU": "Niue",
    "NF": "Norfolk Island",
    "MP": "Northern Mariana Islands",
    "NO": "Norway",
    "OM": "Oman",
    "PK": "Pakistan",
    "PW": "Palau",
    "PS": "Palestinian Territory, Occupied",
    "PA": "Panama",
    "PG": "Papua New Guinea",
    "PY": "Paraguay",
    "PE": "Peru",
    "PH": "Philippines",
    "PN": "Pitcairn",
    "PL": "Poland",
    "PT": "Portugal",
    "PR": "Puerto Rico",
    "QA": "Qatar",
    "RE": "Reunion",
    "RO": "Romania",
    "RU": "Russian Federation",
    "RW": "Rwanda",
    "GS": "S.Georgia   S.Sandwich Island",
    "SH": "Saint Helena",
    "KN": "Saint Kitts And Nevis",
    "LC": "Saint Lucia",
    "PM": "Saint Pierre And Miquelon",
    "VC": "Saint Vincent And The Grenadines",
    "WS": "Samoa",
    "SM": "San Marino",
    "ST": "Sao Tome And Principe",
    "SA": "Saudi Arabia",
    "SN": "Senegal",
    "RS": "Serbia",
    "CS": "Serbia And Montenegro",
    "SC": "Seychelles",
    "SL": "Sierra Leone",
    "SG": "Singapore",
    "SK": "Slovakia",
    "SI": "Slovenia",
    "SB": "Solomon Islands",
    "SO": "Somalia",
    "ZA": "South Africa",
    "ES": "Spain",
    "LK": "Sri Lanka",
    "SD": "Sudan",
    "SR": "Suriname",
    "SJ": "Svalbard And Jan Mayen Islands",
    "SZ": "Swaziland",
    "SE": "Sweden",
    "CH": "Switzerland",
    "SY": "Syrian Arab Republic",
    "TW": "Taiwan",
    "TJ": "Tajikistan",
    "TZ": "Tanzania, United Republic Of",
    "TH": "Thailand",
    "TL": "Timor-Leste",
    "TG": "Togo",
    "TK": "Tokelau",
    "TO": "Tonga",
    "TT": "Trinidad And Tobago",
    "TN": "Tunisia",
    "TR": "Turkey",
    "TM": "Turkmenistan",
    "TC": "Turks And Caicos Islands",
    "TV": "Tuvalu",
    "UG": "Uganda",
    "UA": "Ukraine",
    "AE": "United Arab Emirates",
    "GB": "United Kingdom",
    "US": "United States",
    "UM": "United States Minor Outlying Islands",
    "UY": "Uruguay",
    "UZ": "Uzbekistan",
    "VU": "Vanuatu",
    "VA": "Vatican City State (Holy See)",
    "VE": "Venezuela",
    "VN": "Viet Nam",
    "VG": "Virgin Islands (British)",
    "VI": "Virgin Islands (U.S.)",
    "WF": "Wallis And Futuna Islands",
    "EH": "Western Sahara",
    "YE": "Yemen",
    "ZM": "Zambia",
    "ZW": "Zimbabwe",
    "JE": "Jersey"
  };
  
  /*
   * localeData and guestLocale exists in global scope
   * Sample localeData object below
   * 
   * {
   *   "zipCode":"90011",
   *   "status":"US",
   *   "country":"US",
   *   "countryPhone":"1-800-774-6237",
   *   "specialOffers":"true",
   *   "brochures":"true",
   *   "lastUpdated":"1660634618",
   *   "defaultHomeCity":"UNK",
   *   "primaryCurrency":"USD",
   *   "secondaryCurrency":"CAD",
   *   "isIntl": true,
   *   "isEU": false
   *   "countryName": {
   *     "AX": "Aaland Islands",
   *     "AF": "Afghanistan",
   *     ...
   *     ...
   *   }
   * }
   * 
   */
  var localeData, loc, status, brochures, country, countryPhone, isEU, specialOffers, storedLocale,
  guestLocale = {
  
    /**
    * isReady - fires callback when `localeData` exists
    * @param  {Function} callback
    */
    isReady: (callback) => {
        if (typeof callback == 'function') {
            if (!localeData) {
                document.addEventListener('getLocale', () => {
                callback();
            });
        } else {
            callback();
        }
      }
    },
  
    /**
     * countries - list of country descriptions
     * @type {Object}
     */
    countries: countries
};
  
  
(() => {
  
    // personalizedCountries - list of country we support localization/personalization for
    const personalizedCountries = ["US", "CA", "MX", "AU", "NZ", "UK", "GB", "SG", "HK"];
  
    // getLocale Cookie Name
    const getLocalecookieName = 'getLocale';
  
    /**
    * setIntlData - should getData fail, this will set the user to int'l user
    */
    let setIntlData = () => localeData = {
        brochures: false,
        country: 'IN',
        countryPhone: '',
        isEU: false,
        status: 'IN',
        specialOffers: false
    };
  
    /**
    * setGlobalVariables - Copies localeData to Global variables.
    * This is temporary solution to support backward compatibility but should be cleaned up in future.
    */
     let setGlobalVariables = () => {
        if (localeData) {
            loc = localeData.status;
            status = localeData.status;
            brochures = localeData.brochures;
            country = localeData.country;
            countryPhone = localeData.countryPhone;
            isEU = localeData.isEU;
            specialOffers = localeData.specialOffers;
            localeData.countryName = countries;
            storedLocale = Object.create(localeData);
        }
     };
    
    /**
     * checkData - checks getLocale data and update properties.
     * @param  {object} data - users locale object
     */
    let checkData = (data) => {
        localeData = data;

        if (localeData) {
            // clean up data by replacing plus symbols (+) with spaces ( )
            const reg = /\+/g;

            localeData.city?.replace(reg, ' ');
            localeData.countryPhone?.replace(reg, ' ');

            if (!localeData.status === '') {
                localeData.status = localeData.country;
            }

            localeData.isIntl = personalizedCountries.includes(localeData.country);
            window.digitalDataHelper?.addData('user', 'locale', localeData);
        }
    };
  
    /**
     * buildQUeryParams - accepts json object with key values and returns URL query param string
     * @param {object} queryParams 
     * @returns querystring
     */
    let buildQueryParams = (queryParams) => {
        const params = new URLSearchParams(queryParams);
        return params.toString();
    }
  
    /**
     * getData - call the endpoint to get the guest's locale data and create cookie if response is valid
     * @param {string} queryParamString 
     */
    let getData = (queryParamString) => {
        const apiUrl = `${$gwapiURL}/guest/p1.0/locale`,
            apiUrlWithParams = `${apiUrl}${queryParamString ? `?${queryParamString}` : ''}`,
            config = {
                'method': 'GET',
                'headers': {
                'pcl-client-id': $pclClientId,
                'BookingCompany': 'PC',
                'ProductCompany': 'PC'
            }
        };

        fetch(apiUrlWithParams, config)
            .then((response) => {
                if (response.status === 200) {
                    return response.json();
                } else {
                    throw Error(response.statusText);
                }
            })
            .then((jsonResponse) => {
                if (jsonResponse && jsonResponse.locale) {
                    // Handle valid response
                    checkData(jsonResponse.locale);
                    createCookie();
                } else {
                    // Handle service response error
                    throw Error('Invalid response');
                }
            })
            .catch((error) => {
                // Handle network error
                setIntlData();
            })
            .finally(() => {
                document.dispatchEvent(new CustomEvent('getLocale', {
                    detail: localeData
                }));
                setGlobalVariables();
            });
    };
  
    /**
     * isCookieExpired - checks if cookie is expired.
     * @param  {object} expiryTimestamp - cookie expiry timestamp
     */
    let isCookieExpired = (expiryTimestamp) => {
        if ((new Date(expiryTimestamp)).getTime() > 0) {
            return expiryTimestamp < Math.floor(new Date().getTime() / 1000);
        }
        return true;
    }
  
    /**
    * createCookie - creates cookie using helper functions in documennt-cookies.js 
    */
    let createCookie = () => {
        //if cookie already exists, return
        if(docCookies.getItem("getLocale")){
            return;
        }
        // Cookie attributes
        const time = new Date(),
        cookieExpireTimestamp = new Date(time.getFullYear() + 2, time.getMonth(), time.getDate(), time.getHours(), time.getMinutes());

        // Cookie data
        const timestamp = Math.floor(time.getTime() / 1000),
        ttlInDays = 2,
        ttlInSeconds = ttlInDays * 86400, // Do not change this - Modify number of days in ttlInDays variable
        cookieData = {
            expiry: timestamp + ttlInSeconds,
            timestamp: timestamp,
            ttl: ttlInSeconds,
            ...localeData
        };

        docCookies.setItem(getLocalecookieName, JSON.stringify(cookieData), cookieExpireTimestamp, '/', 'princess.com', true);
    }
  
    /**
    * getLocaleData - checks if getLocale cookie exists and if its valid.
    * If cookie exists and it is not expired, cookie value is set to localeData. 
    * If cookie does not exist or if cookie is expired, getData is called 
    */
    let getLocaleData = (queryParams) => {
        if (queryParams) {
            const queryString = buildQueryParams(queryParams);
            getData(queryString);
            return;
        }

        let getLocaleCookie;
        if (docCookies.hasItem(getLocalecookieName)) {
            getLocaleCookie = JSON.parse(docCookies.getItem(getLocalecookieName));
        }

        if (getLocaleCookie && !isCookieExpired(getLocaleCookie.expiry)) {
            localeData = {...getLocaleCookie};
        } else {
            getData();
        }
        setGlobalVariables();
    };
  
    /**
     * Event listener to update locale data without reloading page
     *  {
     *    detail: {
     *      localeData: {
     *        "country": "US",
     *        "aircity": "LAX",
     *        "zipCode":"90011"
     *      },
     *      app: "pclSearch"
     *    }
     *  }
     */
    window.addEventListener('updateLocale', function (e) {
        const localeInfo = e?.detail?.localeData,
        app = e?.detail?.app;

        getLocaleData(localeInfo);

        // The below code should be run after getLocaleData is updated
        if (app === 'pclSearch' && window[app]) {
            window[app].set('airCityCode', localeInfo.aircity); // AirCityCode
            window[app].set('countryCode', localeInfo.country); // CountryCode
            window[app].set('state', localeInfo.state); // State
            window[app].set('zipCode', localeInfo.zipCode); // ZipCode
        }
    });
  
    getLocaleData();
  
  })()
  